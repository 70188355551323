@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  /* Links */
  .link-ink-background {
    /* TODO stop using these, no need for hover states on links */
    @apply text-ink-background hover:text-ink-background-hover active:text-ink-background-active;
  }

  .link-ink-background-variant {
    /* TODO stop using these, no need for hover states on links */
    @apply text-ink-background-variant hover:text-ink-background-variant-hover active:text-ink-background-variant-active;
  }

  .link-ink-product {
    /* TODO stop using these, no need for hover states on links */
    @apply text-ink-product hover:text-ink-product-hover active:text-ink-product-active;
  }

  /* Headings */
  .heading-1 {
    @apply font-alt font-bold leading-normal my-4 text-3xl;
  }

  .heading-2 {
    @apply font-alt font-bold leading-normal my-3 text-2xl;
  }

  .heading-3 {
    @apply font-alt font-bold leading-normal my-2 text-xl;
  }

  /* Icons */
  .icon-sm {
    @apply h-4 w-4 text-[1rem];
  }

  .icon-base {
    @apply h-6 w-6 text-[1.5rem];
  }

  .icon-lg {
    @apply h-8 w-8 text-[2rem];
  }

  /* Input */
  .input {
    @apply inline-block w-full h-full outline-none focus:outline-none bg-transparent caret-product;
  }
}

@layer utilities {
  /* Typography */
  .text-main {
    @apply font-main text-ink-background;
  }

  .text-alt {
    @apply font-alt text-ink-background;
  }

  /* Layout */
  .top-left {
    @apply absolute top-4 left-4;
  }

  .top-right {
    @apply absolute top-4 right-4;
  }

  .bottom-left {
    @apply absolute bottom-4 left-4;
  }

  .bottom-right {
    @apply absolute bottom-4 right-4;
  }
}
