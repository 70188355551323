/* Avenir */
@font-face {
  font-family: "Avenir LT Std";
  src: url("/fonts/subset-AvenirLTStd-Light.woff2") format("woff2"),
    url("/fonts/subset-AvenirLTStd-Light.woff") format("woff");
  font-display: swap;
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "Avenir LT Std";
  src: url("/fonts/subset-AvenirLTStd-Book.woff2") format("woff2"),
    url("/fonts/subset-AvenirLTStd-Book.woff") format("woff");
  font-display: swap;
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Avenir LT Std";
  src: url("/fonts/subset-AvenirLTStd-LightOblique.woff2") format("woff2"),
    url("/fonts/subset-AvenirLTStd-LightOblique.woff") format("woff");
  font-display: swap;
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: "Avenir LT Std";
  src: url("/fonts/subset-AvenirLTStd-Oblique.woff2") format("woff2"),
    url("/fonts/subset-AvenirLTStd-Oblique.woff") format("woff");
  font-display: swap;
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: "Avenir LT Std";
  src: url("/fonts/subset-AvenirLTStd-BookOblique.woff2") format("woff2"),
    url("/fonts/subset-AvenirLTStd-BookOblique.woff") format("woff");
  font-display: swap;
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: "Avenir LT Std";
  src: url("/fonts/subset-AvenirLTStd-Medium.woff2") format("woff2"),
    url("/fonts/subset-AvenirLTStd-Medium.woff") format("woff");
  font-display: swap;
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "Avenir LT Std";
  src: url("/fonts/subset-AvenirLTStd-MediumOblique.woff2") format("woff2"),
    url("/fonts/subset-AvenirLTStd-MediumOblique.woff") format("woff");
  font-display: swap;
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: "Avenir LT Std";
  src: url("/fonts/subset-AvenirLTStd-Roman.woff2") format("woff2"),
    url("/fonts/subset-AvenirLTStd-Roman.woff") format("woff");
  font-display: swap;
  font-weight: normal;
  font-style: normal;
}

/* Revolution Gothic */
@font-face {
  font-family: "Revolution Gothic";
  src: url("https://use.typekit.net/af/df2606/00000000000000003b9b128a/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n2&v=3")
      format("woff2"),
    url("https://use.typekit.net/af/df2606/00000000000000003b9b128a/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n2&v=3")
      format("woff"),
    url("https://use.typekit.net/af/df2606/00000000000000003b9b128a/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n2&v=3")
      format("opentype");
  font-display: swap;
  font-style: normal;
  font-weight: 200;
}

@font-face {
  font-family: "Revolution Gothic";
  src: url("https://use.typekit.net/af/61e11a/00000000000000003b9b128b/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i2&v=3")
      format("woff2"),
    url("https://use.typekit.net/af/61e11a/00000000000000003b9b128b/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i2&v=3")
      format("woff"),
    url("https://use.typekit.net/af/61e11a/00000000000000003b9b128b/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i2&v=3")
      format("opentype");
  font-display: swap;
  font-style: italic;
  font-weight: 200;
}

@font-face {
  font-family: "Revolution Gothic";
  src: url("https://use.typekit.net/af/222e71/00000000000000003b9b128c/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i3&v=3")
      format("woff2"),
    url("https://use.typekit.net/af/222e71/00000000000000003b9b128c/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i3&v=3")
      format("woff"),
    url("https://use.typekit.net/af/222e71/00000000000000003b9b128c/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i3&v=3")
      format("opentype");
  font-display: swap;
  font-style: italic;
  font-weight: 300;
}

@font-face {
  font-family: "Revolution Gothic";
  src: url("https://use.typekit.net/af/8ea0b7/00000000000000003b9b128d/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n3&v=3")
      format("woff2"),
    url("https://use.typekit.net/af/8ea0b7/00000000000000003b9b128d/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n3&v=3")
      format("woff"),
    url("https://use.typekit.net/af/8ea0b7/00000000000000003b9b128d/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n3&v=3")
      format("opentype");
  font-display: swap;
  font-style: normal;
  font-weight: 300;
}

@font-face {
  font-family: "Revolution Gothic";
  src: url("https://use.typekit.net/af/ac8751/00000000000000003b9b128e/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3")
      format("woff2"),
    url("https://use.typekit.net/af/ac8751/00000000000000003b9b128e/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3")
      format("woff"),
    url("https://use.typekit.net/af/ac8751/00000000000000003b9b128e/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3")
      format("opentype");
  font-display: swap;
  font-style: normal;
  font-weight: 400;
}

@font-face {
  font-family: "Revolution Gothic";
  src: url("https://use.typekit.net/af/4ca915/00000000000000003b9b128f/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i4&v=3")
      format("woff2"),
    url("https://use.typekit.net/af/4ca915/00000000000000003b9b128f/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i4&v=3")
      format("woff"),
    url("https://use.typekit.net/af/4ca915/00000000000000003b9b128f/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i4&v=3")
      format("opentype");
  font-display: swap;
  font-style: italic;
  font-weight: 400;
}

@font-face {
  font-family: "Revolution Gothic";
  src: url("https://use.typekit.net/af/e0fc50/00000000000000003b9b1290/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i7&v=3")
      format("woff2"),
    url("https://use.typekit.net/af/e0fc50/00000000000000003b9b1290/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i7&v=3")
      format("woff"),
    url("https://use.typekit.net/af/e0fc50/00000000000000003b9b1290/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i7&v=3")
      format("opentype");
  font-display: swap;
  font-style: italic;
  font-weight: 700;
}

@font-face {
  font-family: "Revolution Gothic";
  src: url("https://use.typekit.net/af/6ff25a/00000000000000003b9b1291/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3")
      format("woff2"),
    url("https://use.typekit.net/af/6ff25a/00000000000000003b9b1291/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3")
      format("woff"),
    url("https://use.typekit.net/af/6ff25a/00000000000000003b9b1291/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3")
      format("opentype");
  font-display: swap;
  font-style: normal;
  font-weight: 700;
}

@font-face {
  font-family: "Revolution Gothic";
  src: url("https://use.typekit.net/af/9d48ef/00000000000000003b9b1292/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i8&v=3")
      format("woff2"),
    url("https://use.typekit.net/af/9d48ef/00000000000000003b9b1292/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i8&v=3")
      format("woff"),
    url("https://use.typekit.net/af/9d48ef/00000000000000003b9b1292/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i8&v=3")
      format("opentype");
  font-display: swap;
  font-style: italic;
  font-weight: 800;
}

@font-face {
  font-family: "Revolution Gothic";
  src: url("https://use.typekit.net/af/297816/00000000000000003b9b1293/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n8&v=3")
      format("woff2"),
    url("https://use.typekit.net/af/297816/00000000000000003b9b1293/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n8&v=3")
      format("woff"),
    url("https://use.typekit.net/af/297816/00000000000000003b9b1293/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n8&v=3")
      format("opentype");
  font-display: swap;
  font-style: normal;
  font-weight: 800;
}
